<template>
  <div
    class="container-xs"
    style="display: flex; flex-direction: column; height: 100%"
  >
    <AppHeader back-to="/profile/edit" />
    <div style="display: grid; grid-template-columns: 1fr 2fr; gap: 1rem">
      <div style="display: grid; margin-top: 2rem">
        <x-label tag="label" for="call-code">{{ $t('form.phone') }}</x-label>
        <form-input
          id="call-code"
          v-model="callCode"
          name="call-code"
          autocomplete="off"
          list="call-code-list"
          :validator="
            value => phoneCountries.some(country => country.callCode === value)
          "
          required
          @valid="forms.vset.user.phoneCountryId"
          @input="onCallCodeInput"
        />
        <datalist id="call-code-list">
          <option
            v-for="country in phoneCountries"
            :key="country.id"
            :value="country.callCode"
          >
            {{ country.name }}
          </option>
        </datalist>
      </div>
      <form-input
        id="phone"
        v-model="forms.user.phone"
        pattern="\d*"
        style="margin-top: auto"
        type="tel"
        minlength="5"
        required
        @valid="forms.vset.user.phone"
      />
    </div>

    <x-button
      id="save"
      type="large"
      style="margin-top: auto"
      :disabled="!forms.vget.user.form"
      :is-loading="isLoading"
      @click="save"
    >
      {{ $t('form.save') }}
    </x-button>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AppHeader from '../../components-rf/AppHeader.vue'

export default {
  name: 'EditProfilePhone',
  components: { AppHeader },
  created() {
    this.init()
  },
  data() {
    return {
      callCode: '',
      forms: { user: { phone: '', phoneCountryId: '' } },
      isLoading: false,
    }
  },
  methods: {
    ...mapActions('CountryModule', ['getCountries']),
    save() {
      this.isLoading = true
      this.$store
        .dispatch('UserModule/updateUser', this.forms.user)
        .then(() => this.$router.push('/profile/edit'))
        .finally(() => (this.isLoading = false))
    },
    onCallCodeInput(value) {
      const country = this.phoneCountries.find(
        ({ callCode }) => callCode === value,
      )
      if (!country) return
      this.forms.user.phoneCountryId = country.id
      window.event.target.blur()
    },
    async init() {
      if (!this.phoneCountries.length) await this.getCountries()
      const userPhone = this.user && this.user.phone && this.user.phoneCountryId
      const form = this.forms.user
      const countries = this.phoneCountries
      const country = userPhone
        ? countries.find(({ id }) => id === this.user.phoneCountryId)
        : countries.find(
            ({ callCode }) => callCode === 'USA +1' || callCode === 'US +1',
          )
      if (userPhone) {
        form.phone = this.user.phone
        this.forms.vset.user.phone(true)
      }
      if (country) {
        form.phoneCountryId = country.id
        this.forms.vset.user.phoneCountryId(true)
        this.callCode = country.callCode
      }
    },
  },
  computed: {
    ...mapState('UserModule', ['user']),
    ...mapGetters('CountryModule', ['phoneCountries']),
  },
}
</script>

<style scoped lang="scss"></style>
